import React from 'react'
import {not_found_title, not_found_text} from '../components/strings'
import ScrollAnimation from 'react-animate-on-scroll';
import Header from '../components/Header/header'
import Footer from '../components/Footer/footer'
import "animate.css/animate.min.css";
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
     <Header/>
    <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' className="hero is-fullheight thankyou not-found">
         <div className="hero-body">
              <div className="container">
              
                  <h2 className="">{not_found_title}</h2>
                  <p className="">{not_found_text}</p> 
                
              </div>
        
      </div>
 
   </ScrollAnimation>
   <Footer/>
   </Layout>
)

export default NotFoundPage
